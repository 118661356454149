<template>
  <div class="background">
    
      <div class="container content pb-6 pt-6">
          <div class="wrapper-inner">
        <h1 class="is-size-2 is-size-5-mobile -mobile">
          Web 3 is Here! Claim your eternal NFT domain while you still can.
        </h1>

        <b-button
          :href="$settings.UNSTOPPABLE_LINK"
          tag="external-link"
          size="is-medium"
          type="is-red-1"
          label="Get Your Domain Now."
          class="mt-4 mb-4"
        />

        <h2 class="is-size-4 is-size-5-mobile  mb-2">
          Be at the forefront of a disruptive next step in web evolution.
        </h2>
        <p>
          Web 3 will revolutionize web interactions from more efficient and
          trusted payment transactions to enhanced information gathering – all
          without a middleman.
        </p>

        <h2 class="is-size-4 is-size-5-mobile  mb-2">Zero Renewal Fees</h2>
        <p>
          Unlike traditional .com domains, you fully own and control your
          Unstoppable Domain, so you never have to pay renewal fees. Ever. You
          buy it once, you own it for life!
        </p>

        <h2 class="is-size-4 is-size-5-mobile  mb-2">Seamless Crypto Payments</h2>
        <p>
          No longer fear your payments getting lost in the ether Instead of copy
          and pasting your wallet address (which is similar to a bank account
          number and can look like: <span class="break-text">0xc6b0562605d35ee710138402b878ffe6f2e23807</span>),
          use an Unstoppable Domain as your one-stop-shop username to store all
          of your wallet addresses for sending and receiving crypto.
        </p>

        <h2 class="is-size-4 is-size-5-mobile  mb-2">Multi-Chain Compatibility</h2>
        <p>
          Your NFT domain comes with the superpower to receive over 275
          supported coins and tokens, with many more on the way! Now you can
          navigate crypto transactions with ease, regardless of the blockchain
          network. Browse the complete list of supported cryptocurrencies here.
        </p>

        <h2 class="is-size-4 is-size-5-mobile  mb-2">Decentralized Websites</h2>
        <p>
          Want to create a website on the decentralized web? Create one in just
          a few clicks with our pre-made templates. Own any NFTs? Create a
          gallery-style website to show off your favorite collectibles as your
          collection grows. We'll be adding more features and templates in the
          future, so stay tuned here.
        </p>

        <h2 class="is-size-4 is-size-5-mobile  mb-2">Own Your Identity on Web3</h2>
        <p>
          As the world shifts to web3, it’ll be more important than ever to own
          your part of the new internet. By owning an Unstoppable Domain, you’ll
          not only help progress the digital world, you’ll ensure your identity
          is secured for the next wave of technology
        </p>

        <b-button
          :href="$settings.UNSTOPPABLE_LINK"
          tag="external-link"
          size="is-medium"
          type="is-red-1"
          label="Unstoppable Domains"
          class="mt-4 mb-6"
        />

        <p>
          NFT Domains / No renewal fees ever / Building decentralized digital
          identities for the world
        </p>

        <h2 class="is-size-4 is-size-5-mobile  mb-2">Use NFT domains as your:</h2>
        <p>
          Universal username across apps and websites / Website URL / Payment
          address for wallets / Much more!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnstoppableDomains",
};
</script>

<style lang="scss" scoped>
.wrapper-inner {
  width: 50vw;
  max-width: 800px;
}
.background {
  background-image: url(/images/globe-bg.jpg);
  background-repeat: no-repeat;
  background-size: 83vw;
  background-position-x: 100%;
}
@media screen and (max-width:768px){
    .background {
        padding-top: 200px;
        background-position-y: -46px;
        background-size: 610px;
    }
    .wrapper-inner{
        width: 100%;
    }
}
</style>